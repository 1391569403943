<template>
  <nav class="nav">
    <router-link to="/">Home</router-link>
    <router-link to="/markdown-editor">Markdown编辑器</router-link>
    <router-link to="/img-base64">图片转Base64</router-link>
    <router-link to="/signature">签名</router-link>
  </nav>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 20px;

}

nav {
  margin-bottom: 20px;

  a {
    display: inline-block;
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    margin-right: 5px;
    padding: 5px 10px;
    font-size: 16px;
    height: 26px;
    line-height: 26px;
    border-radius: 4px;
    color: #42b983;
    border: 1px solid #42b983;

    &.router-link-exact-active {
      background-color: #42b983;
      color: white;

    }
  }
}
</style>
