<template>
    <VueMarkdownEditor v-model="content" height="calc(100vh - 100px)" placeholder="Input your markdown..."></VueMarkdownEditor>
</template>

<script>
/*
*  这是 MarkdownEditor 模块 by Baron.Bai 2022-10-21
*/
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'

VueMarkdownEditor.use(vuepressTheme, { Prism })

export default {
  name: 'MarkdownEditor',
  components: { VueMarkdownEditor },
  props: {},
  data () {
    return {
      content: ''
    }
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {},
  methods: {}
}
</script>
