import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import MarkdownEditor from '../views/MarkdownEditor.vue'
import ImgToBase64 from '../views/ImgToBase64.vue'
import Signature from '../views/Signature.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/markdown-editor',
    name: 'markdownEditor',
    component: MarkdownEditor
  },
  {
    path: '/img-base64',
    name: 'imgToBase64',
    component: ImgToBase64
  },
  {
    path: '/signature',
    name: 'signature',
    component: Signature
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
