<template>
  <div class="main">
    <canvas ref="myCanvas" width="400" height="400" style="border: 1px solid #dcdfe6;border-radius:4px;"
      @mousedown="startDrawing" @mouseup="stopDrawing" @mousemove="draw"></canvas>
    <button @click="clearCanvas">清空</button>
  </div>
</template>

<script>
/*
*  这是 Signature 组件 by Baron.Bai 2023-10-19
*/

export default {
  name: 'Signature',
  components: {},
  props: {},
  data () {
    return {
      painting: false
    }
  },
  created () { },
  mounted () { },
  watch: {},
  computed: {},
  methods: {
    startDrawing () {
      this.painting = true
    },
    stopDrawing () {
      this.painting = false
      this.$refs.myCanvas.getContext('2d').beginPath()
    },
    draw (event) {
      if (!this.painting) return

      const canvas = this.$refs.myCanvas
      const ctx = canvas.getContext('2d')
      ctx.lineWidth = 2
      ctx.lineCap = 'round'
      ctx.strokeStyle = 'black'

      const rect = canvas.getBoundingClientRect()
      ctx.lineTo(event.clientX - rect.left, event.clientY - rect.top)
      ctx.stroke()
      ctx.beginPath()
      ctx.moveTo(event.clientX - rect.left, event.clientY - rect.top)
    },
    clearCanvas () {
      const canvas = this.$refs.myCanvas
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }
  }
}
</script>

<style lang="less" scoped></style>
