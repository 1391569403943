<template>
  <div class="home">
    <iframe src="https://sejoos.com" frameborder="0"></iframe>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {}
}
</script>

<style scoped lang="scss">
iframe{
  height:calc(100vh - 70px);
  width:100vw;
}
</style>
