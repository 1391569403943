<template>
  <div class="tools">
    <el-upload
    class="upload-demo"
    multiple
    accept="image/*"
    :show-file-list="false"
    :before-upload="beforeUpload"
  >
    <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    <span>请选择图片</span>
  </el-upload>
    <el-button @click="handleImgTag">追加img标签</el-button>
    <el-button class="tag-read" :data-clipboard-text="base64" @click="handleCopyBase64">复制Base64编码</el-button>
  </div>
  <div class="views">
    <span v-if="view==='base64'" class="base64">{{base64}}</span>
    <span v-else class="imgTag">{{base64Tag}}</span>
    <img :src="base64||Empty" alt="源文件">
  </div>
</template>

<script>
/*
*  这是 ImgToBase64 模块 by Baron.Bai 2022-10-22
*/
import { UploadFilled } from '@element-plus/icons-vue'
import Empty from '@/assets/img/empty.png'
import Clipboard from 'clipboard'
const imgTypes = ['image/jpeg', 'image/png']

export default {
  name: 'ImgToBase64',
  components: { UploadFilled },
  props: {},
  data () {
    return {
      Empty,
      base64: '',
      view: 'base64'// base64Tag
    }
  },
  created () {},
  mounted () {},
  watch: {},
  computed: {
    base64Tag () {
      return `<img src="${this.base64}" alt="图片Base64编码" style="max-width:90%; max-height:2000px;"/>`
    }
  },
  methods: {
    beforeUpload (rawFile) {
      const isImg = imgTypes.includes(rawFile.type)
      if (!isImg) {
        this.$message.error('格式不支持，请上传图片')
        return false
      }
      const fileReader = new FileReader()
      fileReader.readAsDataURL(rawFile)
      fileReader.onload = (e) => {
        this.base64 = e.target.result
      }
      return false
    },
    handleImgTag () {
      if (!this.base64) return
      this.view = 'base64Tag'
    },
    handleCopyBase64 () {
      if (!this.base64) return
      const clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message.success('复制成功')
        console.log('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message.error('复制失败')
        // 不支持复制
        console.log('复制失败')
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tools{
  display:flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  .upload-demo{
    margin-right: 10px;
    padding:0 10px;
    height: 30px;
    border:1px solid #409eff;
    background-color: #409eff;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .el-icon--upload{
      font-size: 30px;
      color: white;
    }
    span{
      color: white;
    }
  }
}

.views{
  width: 100%;
  min-width: 400px;
  box-sizing: border-box;
  margin-top: 0px;
  overflow: hidden;
    img{
      float: left;
      margin-top: 20px;
      width: 400px;
      height: 400px;
      border:1px solid #dcdfe6;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 2px 2px 10px rgba($color: #dcdfe6, $alpha: 0.6);
    }
    .imgTag,.base64{
      float: left;
      margin-top: 20px;
      margin-right: 50px;
      word-wrap: break-word;
      width: calc(100% - 450px);
      border-radius: 4px;
      height: 400px;
      min-width: 400px;
      overflow-y: auto;
      padding:5px;
      box-sizing: border-box;
      border:1px solid #dcdfe6;
      font-size: 14px;
      color: #999;
}
}

</style>
